.uploader {
  position: fixed;
  right: 100px;
  top: 50%;
  width: 200px;
}

.image-content {
  position: relative;
  top: 40px;
  left: 40px;
  max-width: 50%;
  max-height: 80%;
}

.url-container {  
  max-width: 100%;
  text-wrap: wrap;        
  padding: 10px;  
  border: 1px solid #ccc;
  border-radius: 10px;  
  word-break: break-all;
  margin: 20px 0;
}