.top-container {  
  top:0;
  width: 100%;
  height: 100px;
  padding: 20px;  
  
  display: flex;
  flex-direction: row;  

  
}

.link {
  font-size: 30px;
  font-weight: bold;
  margin: 20px;  
}