
.uploader {
  position: fixed;
  right: 100px;
  top: 50%;
  width: 200px;
}

.icon-list {
  width: 50vw;
}

.name {
  width: 200px
}